import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  @media(min-width: 1024px){
    flex-direction: row;
  }
`;

export const Content = styled.div`
  flex: 1;

  @media(min-width: 1024px){
    margin-right: 80px;
  }
`;


export const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media(min-width: 1024px){
    width: 340px;
    margin-top: 0px;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;

  border-radius: 8px;
  width: 100%;
  height: 100;
  background: #fff;

  & + div {
    margin: 20px auto;
  }

  span {
    margin-top: 20px;
    color: #203459;
  }

  svg {
    color: #203459;
  }

  ul, li {
    margin-top: 10px;

    span{
      margin-left: 5px;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;

    button {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      background-color: #111;

      &:hover {
        background-color: ${shade(0.5, '#f1f5f9')};
      }
    }
  }

`;

export const Terms = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 10px 20px;
    text-decoration: none;
    transition: color 0.2s;

    width: 200px;

    padding: 10px 20px;
    border-radius: 20px;

  }

  .btndark {
    background-color: #111;

    &:hover {
      background-color: ${shade(0.5, '#f1f5f9')};
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;

    > a {
      margin: 10px 20px;
    }
  }
`;

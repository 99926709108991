import React, { useEffect, useState } from 'react';
import { format } from 'date-fns'
import ReactList from 'react-list';
import { BsCheckBox } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import { abridgedControl } from '../../utils/abridgedControl';
import { FaEllipsisH } from 'react-icons/fa';
import userAvatar from '../../assets/avatars/user1.png';
import api from '../../services/api';
import { statusParse } from '../../utils/statusParse';
import stars from '../../assets/stars.svg';

import {
  Container,
  Card,
  Header,
  Indicated,
  Info,
  Status,
  RewardsCards,
  RewardsCard
} from './styles';

const LatestIndications: React.FC = () => {

  const history = useHistory();
  const [indications, setIndications] = useState<Array<any>>([]);

  useEffect(() => {
    api.get('/indications').then(res => {
      const { data } = res;
      setIndications(data.data);
    })
  }, [])

  const handleClickIndicated = (id: number) => {
    history.push(`reports?id=${id}`);
  }

  const renderCard = (index: any, key: any) => {
    return (
      <Card key={key} onClick={() => handleClickIndicated(indications[index].id)}>
        <Indicated>
          <img src={userAvatar} alt="avatar" />
          <Info>
            <h6>
              {abridgedControl(indications[index].name, 8)}
            </h6>
            <span>
              {format(new Date(indications[index].created_at), 'dd/MM/yy hh:mm')}
            </span>
          </Info>
        </Indicated>
        <Status>
          <p>
            $ {indications[index].value}
          </p>
          <span>
            {!!indications[index].lead && statusParse(indications[index].lead.status)}
          </span>
        </Status>
      </Card>
    )
  }

  const handleScroll = (e: any) => {
    console.log('scroll')
  }

  const handleClick = () => {
    history.push('/indications')
  }

  if (indications.length <= 0) {
    return <Container>
      <RewardsCards>
        <RewardsCard>
          <img src={stars} alt="stars" />
          <span>
            Suas indicações são <br />
            valiosas na nossa base
          </span>
        </RewardsCard>
        <RewardsCard>
          <ul>
            <li>
              <BsCheckBox size={20} />
              <span>
                Indicou, aprovou? Ganhou!
              </span>
            </li>
            <li>
              <BsCheckBox size={20} />
              <span>
                Faça uma integraçao completa
                com sua lista de contatos
              </span>
            </li>
            <li>
              <BsCheckBox size={20} />
              <span>
                Ofereça vantagens exclusivas
                para amigos e familiares.
              </span>
            </li>
          </ul>
        </RewardsCard>
      </RewardsCards>
    </Container>
  }

  return (
    <Container>
      <Header>
        <h4>Últimas <br />
          Indicações</h4>
        <FaEllipsisH color="#8598ad" onClick={handleClick} />
      </Header>
      <div style={{ overflow: 'auto', maxHeight: 400 }} onScroll={handleScroll}>
        <ReactList
          itemRenderer={renderCard}
          length={indications.length}
          type='uniform'
        />
      </div>
    </Container>
  );
}

export default LatestIndications;

import React, { useEffect, useState } from 'react';
import { BsCheckBox } from 'react-icons/bs';
import stars from '../../assets/stars.svg';
import api, { useQuery } from '../../services/api';

import { productParse } from '../../utils/productParse';
import { statusParse } from '../../utils/statusParse';

import {
  FiUsers,
} from 'react-icons/fi';

import {
  Container,
  Content,
  FormSection,
  IndicationData,
  Section,
  Value,
  Title,
  Aside,
  Cards,
  Card
} from './styles';

interface IndicationData {
  name: string;
  document: string;
  phone: string;
  value: string;
  product: string;
  created_at: string;
  id: number;
  updated_at: string;
  lead: any;
}

const Reports: React.FC = () => {

  const id = Number(useQuery().get('id'));
  const [indication, setIndication] = useState<IndicationData>();

  useEffect(() => {
    if (id) {
      api.get(`/indications/${id}`).then(res => {
        setIndication(res.data)
      })
    }
  }, [id])

  return (
    <Container>
      <Content>
        <FormSection>
          <Title>
            <FiUsers strokeWidth="1" size={50} />
            <h1>{indication?.name}</h1>
          </Title>

          <Section>

            <Value>
              <h1>
                Valor Sugerido
              </h1>
              <span>
                R$ {indication?.value}
              </span>
            </Value>

            <IndicationData>
              <ul>
                <li>Produto: {productParse(indication?.product)}</li>
                <li>Documento: {indication?.document}</li>
                <li>Contato: {indication?.phone}</li>
                <li>Status: {indication?.lead && statusParse(indication?.lead.status)}</li>
              </ul>
            </IndicationData>

          </Section>
        </FormSection>
      </Content>

      <Aside>
        <Cards>
          <Card>
            <img src={stars} alt="stars" />
            <span>
              Suas indicações são <br />
              valiosas na nossa base
            </span>
          </Card>
          <Card>
            <ul>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Indicou, aprovou? Ganhou!
                </span>
              </li>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Faça uma integraçao completa
                  com sua lista de contatos
                </span>
              </li>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Ofereça vantagens exclusivas
                  para amigos e familiares.
                </span>
              </li>
            </ul>
          </Card>
        </Cards>
      </Aside>
    </Container>
  );
};

export default Reports;

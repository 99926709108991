import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import {
  Container,
  Content,
  AnimationContainer,
  Background,
  ButtonGroup,
  Footer
} from './styles';

const Welcome: React.FC = () => {

  const history = useHistory();

  const back = () => {
    history.push('/');
  }

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>

          <button onClick={back}>
            <FiChevronLeft />
          </button>

          <h2>
            Clube de benefícios <br />
            Amigo Finmarc: simples, <br />
            prático e feito pra você!
          </h2>

          <ButtonGroup>
            <Link to="/signup" className="btndark" >Abrir conta</Link>
            <Link to="/signin" className="btnIce" >Já tenho conta</Link>
          </ButtonGroup>

          <Footer>
            <Link to="/benefits">
              Conheça as vantagens do <br /> Clube de benefícios, <b>Clique aqui!</b>
            </Link>
          </Footer>
        </AnimationContainer>
      </Content>
    </Container >
  );
}

export default Welcome;

export const statusParse = (slug?: string) => {

  switch (slug?.toLocaleUpperCase()) {
    case 'SUBMITTED':
      return 'Enviado';
    case 'REVIEW':
      return 'Em Análise';
    case 'DOCUMENTATION':
      return 'Documentação';
    case 'REJECTED':
      return 'Negado';
    case 'APPROVED':
      return 'Aprovado';
    default:
      return 'Indefinido'
  }
}

import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import Welcome from '../pages/Welcome';

import Profile from '../pages/Profile';
import Dashboard from '../pages/Dashboard';
import New from '../pages/New';
import Indications from '../pages/Indications';
import Reports from '../pages/Reports';
import Benefits from '../pages/Benefits';
import Invites from '../pages/Invites';
import NewInvite from '../pages/Invites/New';
import Contact from '../pages/Contact';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Welcome} />
      <Route path="/signin" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/benefits" component={Benefits} />

      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/new" component={New} isPrivate />
      <Route path="/indications" component={Indications} isPrivate />
      <Route path="/reports" component={Reports} isPrivate />
      <Route path="/invites" component={Invites} isPrivate />
      <Route path="/newinvite" component={NewInvite} isPrivate />
      <Route path="/contact" component={Contact} isPrivate />

    </Switch>
  );
};

export default Routes;

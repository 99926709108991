import React, { useCallback, useRef } from 'react';
import { FiUser, FiPhone, FiCreditCard, FiDollarSign, FiUserPlus, FiHome } from 'react-icons/fi';
import { BsCheckBox } from 'react-icons/bs';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import stars from '../../assets/stars.svg';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import { useHistory } from 'react-router-dom';
import getValidationErrors from '../../utils/getValidationErrors';
import { isValidCPF } from '../../utils/cpf';
import Cnpj from '../../utils/cnpj';

import Input from '../../components/Input';
import Button from '../../components/Button';
import Select from '../../components/Select';

import {
  Container,
  Content,
  FormSection,
  Title,
  Aside,
  Cards,
  Card
} from './styles';

interface ProfileFormData {
  name: string;
  document: string;
  phone: string;
  value: string;
  product: string;
}

const New: React.FC = () => {

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const history = useHistory()
  const styles = { borderColor: '#111', borderWidth: '1px' }

  const options = [
    { value: 'HOME_EQUITY', label: 'Home Equity' },
    { value: 'PESSOAL', label: 'Empréstimo pessoal' },
    { value: 'EMPRESARIAL', label: 'Empréstimo Empresarial' },
    { value: 'CONSIGNADO', label: 'Crédito Consignado' },
  ]

  const handleSubmit = useCallback(
    async (data: ProfileFormData, { reset }) => {

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          document: Yup.string().required('CPF ou CNPJ obrigatório').test('CPF_CNPJ', 'Cpf inválido', (value) => isValidCPF(value) || Cnpj.isValid(value)),
          phone: Yup.string().required('Telefone obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/indications', data);

        reset()

        addToast({
          type: 'success',
          title: 'Indicação enviada!',
          description:
            'As informações do indicado foram salvas com sucesso!',
        });

        history.push('/indications')
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao salvar',
          description: 'Ocorreu um erro ao salvar o indicado, tente novamente',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Content>
        <FormSection>
          <Title>
            <FiUserPlus strokeWidth="1" size={50} />
            <h1>Nova Indicação</h1>
          </Title>

          <Form
            ref={formRef}
            initialData={{
              name: "",
              document: "",
            }}
            onSubmit={handleSubmit}
          >

            <Select
              name="product"
              options={options}
              icon={FiHome}
              defaultValue={{ value: 'PESSOAL', label: 'Empréstimo pessoal' }}
            />

            <Input
              containerStyle={styles}
              name="name"
              icon={FiUser}
              placeholder="Quem é sua nova indicação?"
            />

            <Input containerStyle={styles}
              name="document" icon={FiCreditCard} placeholder="CPF ou CNPJ" />

            <Input
              containerStyle={styles}
              name="phone"
              icon={FiPhone}
              type="text"
              placeholder="Qual o contato dele?"
            />

            <Input
              containerStyle={styles}
              name="value"
              icon={FiDollarSign}
              type="number"
              min="0"
              placeholder="Qual o valor sugerido?"
            />

            <Button type="submit">Indicar</Button>
          </Form>
        </FormSection>
      </Content>

      <Aside>
        <Cards>
          <Card>
            <img src={stars} alt="stars" />
            <span>
              Suas indicações são <br />
              valiosas na nossa base
            </span>
          </Card>
          <Card>
            <ul>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Indicou, aprovou? Ganhou!
                </span>
              </li>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Faça uma integraçao completa
                  com sua lista de contatos
                </span>
              </li>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Ofereça vantagens exclusivas
                  para amigos e familiares.
                </span>
              </li>
            </ul>
          </Card>
        </Cards>
      </Aside>
    </Container>
  );
};

export default New;

import React, { useEffect, useState } from 'react';
// import DatePicker from "react-datepicker";
import api from '../../services/api';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Header,
  // Filter,
  // InputDate,
  // Label,
  Msg
} from './styles'

const IndicationChart: React.FC = () => {

  // const [startDate, setStartDate] = useState<Date | null>(new Date());
  // const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [data, setData] = useState([])

  // const handleChengeStartDate = (date: any) => {
  //   setStartDate(date)
  // }

  // const handleChengeEndDate = (date: any) => {
  //   setEndDate(date)
  // }

  useEffect(() => {
    api.get('graphics').then(res => {
      const { data } = res;
      setData(data);
    })

  }, [])

  return (
    <Container>
      <Header>
        <span>
          Gráfico
        </span>
        {/* <Filter>
          <InputDate>
            <Label>
              De
            </Label>
            <DatePicker selected={startDate} onChange={handleChengeStartDate} />
          </InputDate>
          <InputDate>
            <Label>
              Até
            </Label>
            <DatePicker selected={endDate} onChange={handleChengeEndDate} />
          </InputDate>
        </Filter> */}

      </Header>
      <ResponsiveContainer width="100%" height="100%">
        {data.length >= 1 ?
          <LineChart
            width={500}
            height={300}
            data={data}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="quant" stroke="#8884d8" activeDot={{ r: 8 }} />
            {/* <Line type="monotone" dataKey="convite" stroke="#82ca9d" /> */}
          </LineChart>
          : <Msg><span> Nenhum indicação realizada</span></Msg>
        }
      </ResponsiveContainer>
    </Container>
  );
}

export default IndicationChart;

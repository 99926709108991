import React, { useState, useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FiCopy, FiEye, FiTrash } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import medias from '../../assets/medias.jpg';
import Button from '../../components/Button';

import {
  Container,
  Content,
  Aside,
  Section,
  Cards,
  Card,
  SocialMedia,
  Header,
  Title,
  Subtitle,
  InviteLink,
  Body,
  Footer,
  Empty
} from './styles';

interface InviteData {
  meta: any;
  data: any;
}

const Invites: React.FC = () => {

  const { addToast } = useToast();
  const history = useHistory();

  const [data, setData] = useState<InviteData>()

  useEffect(() => {
    api.get('/invites').then(res => {
      setData(res.data)
    })
  }, [])

  const handleClickCopy = () => {
    addToast({
      type: 'info',
      title: 'Copiado!!',
      description: 'Link do convite copiado para área de transferência',
    });
  }

  const handleCreateInvite = () => {
    history.push('/newinvite');
  }

  const handleDeleteInvite = (id: number) => {
    api.delete(`/invites/${id}`).then(res => {
      //Todo remover do state o convite
    })
  }

  const handlePreview = (url: string) => {
    window.open(url, '_blank');
  }

  return (
    <Container>
      <Aside>
        <Section>
          <Button disabled={data?.data.length < 3 ? false : true} onClick={handleCreateInvite} > Criar Convite </Button>
        </Section>

        <Cards>
          <SocialMedia>
            <img src={medias} alt="social medias" width="280px" />
            <span>
              Crie seu convite <br />
              e compartilhe nas redes sociais
            </span>
          </SocialMedia>
        </Cards>
      </Aside>
      <Content>
        <h3>
          Meus Convites
        </h3>
        {
          data?.data.length <= 0 ? (<Empty><p> Nenhum convite foi criado!</p></Empty>) :
            (
              <Cards>
                {
                  data?.data.map((item: any, index: number) => (
                    <Card key={index}>
                      <Header />
                      <Body>
                        <Title>
                          {item.title}
                        </Title>
                        <Subtitle>
                          {item.subtitle}
                        </Subtitle>
                        <InviteLink>
                          {`invite.finmarc.com.br?q=${item.code}`}
                        </InviteLink>
                      </Body>
                      <Footer>
                        <CopyToClipboard text={`https://www.invite.finmarc.com.br?q=${item.code}`}
                          onCopy={handleClickCopy}>
                          <FiCopy strokeWidth={1} size={20} />
                        </CopyToClipboard>
                        <FiEye strokeWidth={1} size={20} onClick={() => handlePreview(`https://www.invite.finmarc.com.br?q=${item.code}`)} />
                        <FiTrash strokeWidth={1} size={20} onClick={() => handleDeleteInvite(item.id)} />
                      </Footer>
                    </Card>
                  ))
                }
              </Cards>
            )}
      </Content>
    </Container>
  );
};

export default Invites;

export const productParse = (slug?: string) => {

  switch (slug?.toLocaleUpperCase()) {
    case 'PESSOAL':
      return 'Empréstimo Pessoal';
    case 'HOME_EQUITY':
      return 'Home Equity';
    case 'CONSIGNADO':
      return 'Consignado';
    case 'EMPRESARIAL':
      return 'Empréstimo Empresarial';
    default:
      return 'Outro'
  }
}

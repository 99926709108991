import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  @media(min-width: 1024px){
    flex-direction: row;
  }
`;

export const Content = styled.div`
  flex: 1;
  padding: 20px;

  @media(min-width: 1024px){
    margin-right: 80px;
    padding: 0px;
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-direction: column;

  background: #fff;
  border-radius: 8px;
  padding: 30px;

  @media(min-width: 1024px){
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
`;

export const Section = styled.div`
  flex: 1;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;

    @media(min-width: 1024px){
      margin: 40px 0px 20px 20px;
    }

    button {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      background-color: #111;

      &:hover {
        background-color: ${shade(0.5, '#f1f5f9')};
      }
    }
  }
`;

export const Value = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  > h1 {
      color: #203459;
      font-size: 30px;
    }

  > span {
      color: #203459;
      font-size: 30px;
      font-weight: bold;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  margin-bottom: 20px;

  svg {
    color: #203459;
  }

  h1 {
    margin-bottom: 24px;
    font-size: 22px;
    font-weight: 300;
    text-align: left;
    color: #203459;

    margin-left: 20px;
  }

  @media(min-width: 1024px){
    flex-direction: column;
    max-width: 120px;
    height: auto;

    h1 {
      margin-left: auto;
    }
  }
`;

export const IndicationData = styled.div`
  padding: 20px;

  > ul, li {
    font-size: 18px;
    font-weight: 300;
    color: #203459;
  }
`;

export const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  padding: 20px;

  @media(min-width: 1024px){
    width: 340px;
    padding: 0px;
  }
`;


export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;

  border-radius: 8px;
  width: 100%;
  height: 100;
  background: #fff;

  & + div {
    margin: 20px auto;
  }

  span {
    margin-top: 20px;
    color: #203459;
  }

  svg {
    color: #203459;
  }

  ul, li {
    margin-top: 10px;

    span{
      margin-left: 5px;
    }
  }

`;

import React, { useCallback, useRef, useState, useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { FcNext, FcPrevious } from 'react-icons/fc';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import api, { useQuery } from '../../services/api';
import { statusParse } from '../../utils/statusParse';
import { useToast } from '../../hooks/toast';
import LatestIndications from '../../components/LatestIndications';
import { isMobile } from 'react-device-detect';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';
import { productParse } from '../../utils/productParse';
import {
  Container,
  Content,
  Search,
  Pagination
} from './styles';
import { abridgedControl } from '../../utils/abridgedControl';

interface ProfileFormData {
  name: string;
  document: string;
  phone: string;
  value: string;
  product: string;
}

interface IndicationsData {
  meta: any;
  data: any;
}

interface ProfileFormData {
  keyword: string;
}

const Indications: React.FC = () => {

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const styles = { borderColor: '#111', border: 'none', heigth: 30 }

  const status = useQuery().get('status');
  const [data, setData] = useState<IndicationsData>()
  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    const url = status ? `/indications?status=${status}` : `/indications`;

    api.get(url).then(res => {
      setData(res.data)
    })
  }, [status])

  const handleSubmit = useCallback(
    async (data: ProfileFormData, { reset }) => {

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          keyword: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { keyword } = data
        const res = await api.get(`/indications?keyword=${keyword}&page=${page}`);

        setData(res.data)

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao pesquisar',
          description: 'Ocorreu um erro ao pesquisar os indicados, tente novamente',
        });
      }
    },
    [addToast, page],
  );

  const handleClickPaginate = (cpage: number) => {
    setPage(cpage)
  }

  return (
    <Container>
      {isMobile ? (
        <Content>
          <LatestIndications />
        </Content>
      ) : (
        <Content>
          <Search>
            <Form
              ref={formRef}
              initialData={{
                keyword: "",
              }}
              onSubmit={handleSubmit}
            >
              <Input
                containerStyle={styles}
                name="keyword"
                icon={FiSearch}
                placeholder="Pesquise seus indicados"
              />
              <Button type="submit">Pesquisar</Button>
            </Form>
          </Search>
          <table className="fl-table">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Documento</th>
                <th>Contato</th>
                <th>Produto</th>
                <th>valor</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {
                data?.data.map((item: any) => (
                  <tr key={item.id}>
                    <td>{abridgedControl(item.name, 15)}</td>
                    <td>
                      <Link to={`/reports?id=${item.id}`}>
                        {item.document}
                      </Link>
                    </td>
                    <td>{item.phone}</td>
                    <td>{productParse(item.product)}</td>
                    <td>{item.value}</td>
                    <td>{!!item.lead && statusParse(item.lead.status)}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <Pagination>
            <span>
              Página {data?.meta.first_page} de {data?.meta.last_page}
            </span>

            <button onClick={() => { handleClickPaginate(page - 1) }}>
              <FcPrevious />
            </button>
            <button onClick={() => { handleClickPaginate(page + 1) }}>
              <FcNext />
            </button>
          </Pagination>
        </Content>
      )}
    </Container>
  );
};

export default Indications;

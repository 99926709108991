import React, { useCallback, useRef } from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import { BsCheckBox } from 'react-icons/bs';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import stars from '../../assets/stars.svg';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/Input';
import Button from '../../components/Button';

import {
  Container,
  Content,
  Aside,
  Cards,
  Card,
  Terms
} from './styles';

interface ContactFormData {
  message: string;
}

const Contact: React.FC = () => {

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const styles = { borderColor: '#111', borderWidth: '1px' }

  const handleSubmit = useCallback(
    async (data: ContactFormData, { reset }) => {

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          message: Yup.string().required('Messagem obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/contacts', data);

        reset()

        addToast({
          type: 'success',
          title: 'Messagem enviada!',
          description:
            'Em breve um consultor entrará em contato',
        });

      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao salvar',
          description: 'Ocorreu um erro ao enviar a mensagem, tente novamente',
        });
      }
    },
    [addToast],
  );

  return (
    <Container>
      <Content>
        <Cards>
          <Card>
            <ul>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Indicou, aprovou? Ganhou!
                </span>
              </li>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Faça uma integraçao completa
                  com sua lista de contatos
                </span>
              </li>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Ofereça vantagens exclusivas
                  para amigos e familiares.
                </span>
              </li>
            </ul>
          </Card>
          <Card>
            <Terms>
              <span>
                Leia os termos de uso para mais informações
              </span>
              <a target="_blank" rel="noopener noreferrer" href="https://www.club.finmarc.com.br/docs/terms.pdf" className="btndark" >Termos</a>
            </Terms>
          </Card>
        </Cards>

      </Content>

      <Aside>
        <Cards>
          <Card>
            <img src={stars} alt="stars" />
            <span>
              Suas indicações são <br />
              valiosas na nossa base
            </span>
          </Card>

          <Card>
            <Form
              ref={formRef}
              initialData={{
                name: "",
                document: "",
              }}
              onSubmit={handleSubmit}
            >
              <Input
                containerStyle={styles}
                name="message"
                icon={FiMessageCircle}
                type="text"
                min="0"
                placeholder="Messagem"
              />

              <Button type="submit">Enviar</Button>
            </Form>
          </Card>

        </Cards>


      </Aside>
    </Container>
  );
};

export default Contact;

import React, { useCallback, useRef } from 'react';
import { BsCheckBox } from 'react-icons/bs';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import medias from '../../../assets/medias.jpg';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { useHistory } from 'react-router-dom';
import getValidationErrors from '../../../utils/getValidationErrors';

import Input from '../../../components/Input';
import Button from '../../../components/Button';
import {
  MdTitle,
  MdSubtitles
} from 'react-icons/md';

import { BiLinkExternal } from 'react-icons/bi';

import {
  FiLink,
  FiSend
} from 'react-icons/fi';

import {
  Container,
  Content,
  FormSection,
  Title,
  Aside,
  Cards,
  Card
} from './styles';

interface ProfileFormData {
  title: string;
  subtitle: string;
  code: string;
  callback: string;
}

const New: React.FC = () => {

  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const history = useHistory();
  const styles = { borderColor: '#111', borderWidth: '1px' }

  const handleSubmit = useCallback(
    async (data: ProfileFormData, { reset }) => {

      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string().required('Título obrigatório'),
          code: Yup.string()
            .required('Código obrigatório'),
          subtitle: Yup.string().required('Subtítulo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('/invites', data);

        reset()

        addToast({
          type: 'success',
          title: 'Convite criado!',
          description:
            'As informações do convite foram salvas com sucesso!',
        });

        history.push('/invites');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro ao salvar',
          description: 'Ocorreu um erro ao salvar o indicado, tente novamente',
        });
      }
    },
    [addToast, history],
  );

  return (
    <Container>
      <Content>
        <FormSection>
          <Title>
            <FiSend strokeWidth="1" size={50} />
            <h1>Novo convite</h1>
          </Title>

          <Form
            ref={formRef}
            initialData={{
              title: "",
              code: "",
            }}
            onSubmit={handleSubmit}
          >

            <Input
              containerStyle={styles}
              name="title"
              icon={MdTitle}
              placeholder="Título"
            />

            <Input containerStyle={styles}
              name="subtitle" icon={MdSubtitles} placeholder="Subtítulo" />

            <Input
              containerStyle={styles}
              name="code"
              icon={FiLink}
              type="text"
              placeholder="Código"
            />

            <Input
              containerStyle={styles}
              name="callback"
              icon={BiLinkExternal}
              type="text"
              min="0"
              placeholder="redirecionar para?"
            />

            <Button type="submit">Criar</Button>
          </Form>
        </FormSection>
      </Content>

      <Aside>
        <Cards>
          <Card>
            <img src={medias} alt="social medias" width="300px" />
            <span>
              Crie seu convite <br />
              e compartilhe nas redes sociais
            </span>
          </Card>
          <Card className="bg-trans">
            <ul>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Título e subtítulo personalizável.
                </span>
              </li>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Keyword amigáveis no link do convite.
                </span>
              </li>
              <li>
                <BsCheckBox size={20} />
                <span>
                  Link de redirecionamento para onde desejar.
                </span>
              </li>
            </ul>
          </Card>
        </Cards>
      </Aside>
    </Container>
  );
};

export default New;

import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import benefitsBackground from '../../assets/bg/benefits.jpg';

export const Container = styled.div`

`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;

  width: 100%;
  max-width: 600px;

`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  animation: ${appearFromRight} 1s;

  > h2 {
    font-size: 23px;
    margin-bottom: 40px;

    @media (min-width: 1024px) {
      font-size: 38px;
      line-height: 40px;
    }
  }

  > button {
    border: none;
    background-color: transparent;
    color: #ffffff;
    display: block;
    margin: 20px 0px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      height: 40px;
      width: 40px;
      margin-right: 16px;
      border-radius: 8px;
      border: 2px solid;
      padding: 5px;
    }

    &:hover {
      color: ${shade(0.2, '#111')};
    }
  }
`;


export const Aside = styled.div`
  display: flex;
  flex-direction: column;

  @media(min-width: 1024px){
    margin-left: -700px;
    flex-direction: row;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px auto;
  max-width: 1000px;
`;

export const SectionBg = styled.div`
  height: 100vh;

  display: flex;
  align-items: stretch;
`;

export const Background = styled.div`
  flex: 1;
  background: url(${benefitsBackground}) no-repeat center;
  background-size: cover;
  border-radius: 0px 0px 14px 0px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 10px 20px;
    text-decoration: none;
    transition: color 0.2s;

    width: 200px;

    padding: 10px 20px;
    border-radius: 20px;

  }

  .btndark {
    background-color: #111;

    &:hover {
      background-color: ${shade(0.5, '#f1f5f9')};
    }
  }

  .btnIce {
    color: #111;
    background-color: #f1f5f9;

    &:hover {
      background-color: ${shade(0.2, '#f1f5f9')};
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;

    > a {
      margin: 10px 20px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;

  > h1{
    font-size: 50px;
    line-height: 50px;
    text-align: left;
  }

  @media(min-width: 1024px){
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    margin-right: 200px;
  }
`;

export const Cards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;

  border-radius: 8px;
  width: 100%;
  height: 100;
  background: #fff;

  & + div {
    margin: 20px auto;
  }

  span {
    margin-top: 20px;
    color: #203459;
  }

  svg {
    color: #203459;
  }

  ul, li {
    margin-top: 10px;

    span{
      margin-left: 5px;
    }
  }

`;

export const Faqs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media(min-width: 1024px){
    padding: 0px;
  }
`;

export const Faq = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;
  width: 100%;

  h1 {
    color: #fff;
  }
`;

export const Servicoes = styled.div`
  display: block;

  table {
    margin: 20px;
  }

  td, th {
    font-size: 14px;
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  @media(min-width: 1024px){
    display: grid;
    grid-template-columns: 1fr 1fr;
	  grid-gap: 10px;

  }
`;

export const Footer = styled.div`

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  > p {
    color: #fff;
    font-size: 14px;
    font-weight: 200;
    margin-bottom: 20px;
  }

  @media(min-width: 1024px){
    padding: 0px;
  }
`;

export const Terms = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 10px 20px;
    text-decoration: none;
    transition: color 0.2s;

    width: 200px;

    padding: 10px 20px;
    border-radius: 20px;

  }

  .btndark {
    background-color: #111;

    &:hover {
      background-color: ${shade(0.5, '#f1f5f9')};
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;

    > a {
      margin: 10px 20px;
    }
  }
`;

export const isValidCPF = (number: string | null | undefined) => {
  let sum = 0;
  let rest;

  if (typeof number !== "string") return false;
  if (!number) return false;

  number = number.replace(/([^\d])+/gim, '');

  if (number.length !== 11 || !Array.from(number).filter(e => e !== number![0]).length) {
    return false
  }

  for (let i = 1; i <= 9; i++) sum = sum + parseInt(number.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11)) rest = 0;
  if (rest !== parseInt(number.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) sum = sum + parseInt(number.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if ((rest === 10) || (rest === 11)) rest = 0;
  if (rest !== parseInt(number.substring(10, 11))) return false;

  return true;
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import IndicationChart from '../../components/IndicationChart';
import LatestIndications from '../../components/LatestIndications';
import {
  FiUserPlus,
  FiUsers,
  FiUserCheck
} from 'react-icons/fi';

import {
  Container,
  Graphics,
  Indications,
  Cards,
  Card
} from './styles';

const Dashboard: React.FC = () => {

  const history = useHistory();

  const handleClickCard = (page: string) => {
    history.push(page);
  }

  return (
    <Container>
      <Graphics>
        <Cards>
          <Card onClick={() => handleClickCard('/new')}>
            <FiUserPlus strokeWidth="1" size={50} />
            <span>
              Adicionar <br />
              indicado
            </span>
          </Card>

          <Card onClick={() => handleClickCard('/indications')}>
            <FiUsers strokeWidth="1" size={50} />
            <span>
              Pessoas <br />
              indicadas
            </span>
          </Card>

          <Card onClick={() => handleClickCard('/indications?status=APPROVED')}>
            <FiUserCheck strokeWidth="1" size={50} />
            <span>
              Processos <br />
              concluídos
            </span>
          </Card>
        </Cards>

        <IndicationChart />

      </Graphics>

      <Indications>
        <LatestIndications />
      </Indications>
    </Container>
  );
};

export default Dashboard;

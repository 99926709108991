import React from 'react';
import { BsCheckBox } from 'react-icons/bs';
import stars from '../../assets/stars.svg';
import { Link } from 'react-router-dom';

import {
  Container,
  Content,
  AnimationContainer,
  Section,
  SectionBg,
  Background,
  ButtonGroup,
  Aside,
  Header,
  Footer,
  Cards,
  Card,
  Faqs,
  Faq,
  Servicoes,
  Terms
} from './styles';

const Benefits: React.FC = () => {

  return (
    <Container>
      <SectionBg>
        <Background />
        <Content>
          <AnimationContainer>

            <Aside>
              <Header>
                <h1>
                  Amigo <br />
                  Finmarc
                </h1>
                <span>
                  Conheça as vantagens do <br /> Clube de benefícios:
                </span>
              </Header>
              <Cards>
                <Card>
                  <img src={stars} alt="stars" />
                  <span>
                    Suas indicações são <br />
                    valiosas na nossa base
                  </span>
                </Card>
                <Card>
                  <ul>
                    <li>
                      <BsCheckBox size={20} />
                      <span>
                        Indicou, aprovou? Ganhou!
                      </span>
                    </li>
                    <li>
                      <BsCheckBox size={20} />
                      <span>
                        Faça uma integraçao completa
                        com sua lista de contatos
                      </span>
                    </li>
                    <li>
                      <BsCheckBox size={20} />
                      <span>
                        Ofereça vantagens exclusivas
                        para amigos e familiares.
                      </span>
                    </li>
                  </ul>
                </Card>
                <ButtonGroup>
                  <Link to="/signup" className="btndark" >Abrir conta</Link>
                  <Link to="/signin" className="btnIce" >Já tenho conta</Link>
                </ButtonGroup>
              </Cards>
            </Aside>
          </AnimationContainer>
        </Content>
      </SectionBg >
      <Section>
        <Faqs>
          <Faq>
            <h1>
              O que seria?
            </h1>
            <p>
              Um programa para captação de novos clientes e parceiros que permitirá uma premiação conforme suas indicações.
            </p>
            <p>
              O Amigo Finmarc ao indicar, irá receber uma premiação por cada contrato fechado.
            </p>
          </Faq>

          <Faq>
            <h1>
              Proposta
            </h1>
            <p>
              Estruturar uma rede de relacionamento e networking que o Amigo Finmarc possa <mark> ganhar até R$1.000,00 por cada indicação </mark> com contrato assinado e pago, de acordo com o produto negociado.
              O objetivo é ganharmos escala nas indicações diretas.
            </p>
          </Faq>
          <Faq>
            <h1>
              Serviços
            </h1>
            <p>
              O Amigo Finmarc pode oferecer qualquer um dos produtos e valor a ser pago dependerá do valor do contrato
            </p>
            <Servicoes>
              <table>
                <caption>
                  Home equity
                </caption>
                <thead>
                  <tr>
                    <th>Valores</th>
                    <th>Ganhos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      De R$50.000 a R$59.999
                    </td>
                    <td>
                      Ganho de R$ 500,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$60.000 a R$69.999
                    </td>
                    <td>
                      Ganho de R$ 600,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$70.000 a R$79.999
                    </td>
                    <td>
                      Ganho de R$ 700,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$80.000 a R$89.999
                    </td>
                    <td>
                      Ganho de R$ 800,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$90.000 a R$99.999
                    </td>
                    <td>
                      Ganho de R$ 900,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Contratos acima de R$100.000,00
                    </td>
                    <td>
                      Ganho de R$ 1.000,00
                    </td>
                  </tr>
                </tbody>
              </table>

              <table>
                <caption>
                  Capital Giro
                </caption>
                <thead>
                  <tr>
                    <th>Valores</th>
                    <th>Ganhos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      De R$50.000 a R$59.999
                    </td>
                    <td>
                      Ganho de R$ 250,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$60.000 a R$69.999
                    </td>
                    <td>
                      Ganho de R$ 300,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$70.000 a R$79.999
                    </td>
                    <td>
                      Ganho de R$ 350,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$80.000 a R$89.999
                    </td>
                    <td>
                      Ganho de R$ 400,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$90.000 a R$99.999
                    </td>
                    <td>
                      Ganho de R$ 450,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Contratos acima de R$100.000,00
                    </td>
                    <td>
                      Ganho de R$ 500,00
                    </td>
                  </tr>
                </tbody>
              </table>

              <table>
                <caption>
                  Refinanciamento de Veículo
                </caption>
                <thead>
                  <tr>
                    <th>Valores</th>
                    <th>Ganhos</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      De R$5.000 a R$9.999
                    </td>
                    <td>
                      Ganho de R$ 250,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$10.000 a R$19.999
                    </td>
                    <td>
                      Ganho de R$ 50,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$20.000 a R$29.999
                    </td>
                    <td>
                      Ganho de R$ 100,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$30.000 a R$39.999
                    </td>
                    <td>
                      Ganho de R$ 150,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      De R$40.000 a R$49.999
                    </td>
                    <td>
                      Ganho de R$ 200,00
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Contratos acima de R$50.000,00
                    </td>
                    <td>
                      Ganho de R$ 250,00
                    </td>
                  </tr>
                </tbody>
              </table>
            </Servicoes>
          </Faq>
        </Faqs>
        <Terms>
          <span>
            Leia os termos de uso para mais informações
          </span>
          <a target="_blank" rel="noopener noreferrer" href="https://www.club.finmarc.com.br/docs/terms.pdf" className="btndark" >Termos</a>
        </Terms>
        <Footer>
          <p>
            A Finmarc não realiza qualquer atividade privativa de instituições financeiras, como financiamentos e/ou empréstimos. Essas operações são realizadas através de parceiros autorizados pelo BACEN, nos termos da Resolução nº 3.954, de 24 de fevereiro de 2011. As operações são realizadas através das seguintes instituições: Companhia Hipotecária Piratini – CHP (CNPJ 18.282.093/0001-50).
          </p>
          <p>
            Informações complementares referentes ao Empréstimo com Garantia de Imóvel: mínimo de 36 meses e máximo de 240 meses. “””Exemplo – Empréstimo de R$ 200.000,00 para pagar em 20 anos (240 meses) com LTV de 42,00% (sendo R$ 200.000,00 + despesas acessórias, para um apartamento avaliado em R$ 500.000,00) – Prestação inicial de R$ 2.632,79, com uma taxa de juros de 0,79% ao mês + IPCA, Sistema de Amortização Tabela SAC. CET de 10,85% ao ano.”””
          </p>
          <p>
            Taxas de empréstimo segundo informação disponível no site do Banco Central:
            Empréstimo Pessoal: 5,50% a 24,41% ao mês - Empréstimo Consignado Público: 3,67% a 5,96% ao mês - Empréstimo Consignado Privado: 1,99% a 7,99% ao mês - Financiamento de veículo - de 2,69% a 6,04% ao mês.
          </p>
        </Footer>
      </Section >
    </Container>
  );
}

export default Benefits;
